<template>
  <button
    v-tooltip="{
      content: $t(translationKeys.tooltipMsg),
      placement: placement,
      offset: offset,
      delay: {
        show: delay.show,
        hide: delay.hide,
      },
    }"
    class="apptooltip"
  >
    <img src="/imgs/ask-tooltip.svg" alt="icono de consejo" />
  </button>
</template>
<script>
export default {
  name: 'AppTooltip',
  props: {
    translationKeys: {
      type: Object,
      require: true,
    },
    offset: {
      type: Number,
      require: true,
    },
    delay: {
      type: Object,
      require: true,
    },
    placement: {
      type: String,
      require: true,
      validator: (propValue) => {
        const listOfValidPlacements = [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'right',
          'right-start',
          'right-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'bottom-center',
          'left',
          'left-start',
          'left-end',
        ]
        const isValidPlacement = listOfValidPlacements.some((extension) => propValue === extension)
        return isValidPlacement
      },
    },
  },
}
</script>
<style lang="scss">
.tooltip {
  z-index: 10000;
  display: block;

  .tooltip-inner {
    padding: 25px;
    background: #1973b8;
    border-radius: 0;
    color: rgb(255, 255, 255);
  }

  .tooltip-arrow {
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #1973b8;
    margin: 5px;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      bottom: -5px;
      left: calc(50% - 5px);
      border-width: 5px 5px 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      top: -10px;
      left: calc(50% - 5px);
      border-width: 0 10px 10px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      left: -5px;
      border-width: 5px 5px 5px 0;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      right: -5px;
      border-width: 5px 0 5px 5px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
</style>
